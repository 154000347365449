import React from "react";
import { Link } from "gatsby"
import useBreakpoint from "../../../hooks/useBreakpoint";
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { useContent } from "../../../hooks/useContent";
import { Container, Row, Col } from "react-bootstrap";
import EntityLogos from "../../EntityLogos"

const StyledContainer = styled.section`
  background-color: #FFECC6;
`

const Hero = () => {

  const content = useContent()

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledContainer>
        <div 
          className="position-relative z-index-1"
        >
          <Container>
            <Row 
              className="position-relative align-items-center"
            >
              <Col
                className="col-xxl-7 col-xl-8 col-lg-9 pb-10 pt-10 pb-lg-20 pt-xxl-20"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-dealy="750"
              >
                <Row className="mb-10">
                  <Col className="col-lg-8 col-xl-9 col-xxl-10">
                    <EntityLogos size={2}/>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-8 col-sm-10">
                    <p className="font-size-7 font-weight-bold">
                      { content.get('homepage-hero-title', true) }
                    </p>
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col>
                    <Link
                        to="/search"
                        className="btn btn-primary mr-8 my-4 text-uppercase w-100 w-sm-auto"
                        size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                    >
                        View vacancies
                    </Link>
                    <Link
                        to="/mentorship"
                        className="btn btn-secondary text-uppercase w-100 w-sm-auto my-4"
                        size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                    >
                        Explore mentorship
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 pos-abs-tr z-index-n1 position-static position-lg-absolute ml-auto hero-image-positioning-2 h-100 overflow-hidden d-none d-md-block">
                <Row className="image-group justify-content-center">
                  <Col className="col-sm-12 col-10 mt-10 mt-lg-0">
                    <div
                      className="single-image"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-dealy="750"
                    >
                      <StaticImage
                        src="../../../assets/image/photo_cofra_greenery.jpg"
                        alt="Green lush background. Fancy lamp bulbs hanging from the ceiling"
                        placeholder="blurred"
                        layout="fixed"
                        quality={100}
                        width={1200}
                        height={700}
                        transformOptions={{fit: "cover", cropFocus: 'southwest'}}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </StyledContainer>
    </>
  );
};

export default Hero;

