import React from "react"
import { Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

const InfoColumn = ({icon='fa-solid fa-hand-holding-heart', title='', text=null, buttonText='', buttonLink=''}) => {
    return (
        <>
            <div className="infocolumn-icon text-black text-center mx-auto mb-3">
                <i className={`${icon} font-size-8 text-black`}></i>
            </div>
            <div>
                <h2 className="infocolumn-title font-family-primary font-size-6 mb-3 text-center">{title}</h2>
                { text && 
                    (<p className="infocolumn-text font-size-4 text-default-color text-center">
                        {text}
                    </p>)
                }
            </div>
            <div className="infocolumn-button d-flex justify-content-center">
                <Button variant="link">
                    <Link to={ buttonLink }>{ buttonText } <i className="fas fa-arrow-right ml-4"></i></Link>
                </Button>
            </div>
        </>
    )
}

export default InfoColumn