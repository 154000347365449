import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import InfoColumn from "../../Core/InfoColumn";

const StyledContainer = styled.section`
  background-color: white;
`

const Services = () => {

  const query = useStaticQuery(graphql`
    {
        allSharePointPageContentList( filter: {data: {fields: {Title: {eq: "homepage"}}}}) 
        {
            content: nodes {
                data {
                    fields {
                        Section
                        Content
                    }
                }
            }
        }
    }
  `
  )

  const { content } = query.allSharePointPageContentList

  return (
    <>
      {" "}
      {/* <!-- Services Area --> */}
      <StyledContainer>
        <div className="pt-11 pt-lg-26 pb-7 pb-lg-18">
          <Container className="container">
            {/* <!-- Section title --> */}
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
              </div>
            </div>
            {/* <!-- End Section title --> */}
            {/* <!-- Services Content --> */}
            <div
              className="row justify-content-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {/* <!-- Single Services --> */}
              <Row className="mb-8 mb-lg-18 px-xl-9 px-xxl-7">
                {[
                  {
                    icon: "fa fa-hands-helping",
                    title: 'Partnering to belong',
                    buttonText: 'View entities involved',
                    text: 'We want our people to be able to drive our future by building meaningful relationships across the group. Do you want to know more about the participating entities?',
                    buttonLink: "/#entities"
                  },
                  {
                    icon: "fa fa-arrow-alt-circle-up",
                    title: 'Adapting to grow',
                    buttonText: 'Check latest opportunities',
                    text: 'We attract and develop fit-for-future capabilities to equip our people to grow and deliver the business strategies. This portal will help you find new challenges to take on. Browse through the available roles and projects.',
                    buttonLink: "/search"
                  },
                  {
                    icon: "fa fa-user-check",
                    title: 'Making good matter',
                    buttonText: 'Find out more about the process',
                    text: "We build an inclusive culture where people can continue to develop and make an impact, inspired by the family owners' mission and values. Are you seeking a new development opportunity?",
                    buttonLink: "/what-you-need-to-know"
                  }
                ].map((item) => (
                  <Col className="px-10">
                    <InfoColumn {...item} />
                  </Col>
                ))}
              </Row>
              {/* <!-- End Single Services --> */}
            </div>
            {/* <!-- End Services Content --> */}
          </Container>
        </div>
      </StyledContainer>
    </>
  );
};

export default Services;
