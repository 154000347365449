import React from "react";
import { logos } from "../Header/HeaderLogos";
import styled from "styled-components";
import { device } from "../../utils";

const EntityLogos = ({ size=2 }) => {
    
    const StyledDiv = styled.div`
        & img.logo {
            height: ${size}rem;
            @media ${device.sm} {
                height: ${size}rem !important;
            }
            @media ${device.md} {
                height: ${size}rem !important;
            }
            @media ${device.lg} {
                height: ${size}rem !important;
            }
            @media ${device.xl} {
                height: ${size*1.2}rem !important;
            }
            @media ${device.xxl} {
                height: ${size*1.4}rem !important;
            }
        }
    `

    return (
        <StyledDiv className="d-flex align-items-end justify-content-between">
            { logos.map((item) => 
                (
                    <a
                        className="w-100 w-sm-auto mb-5 mb-sm-0 text-center"
                        href={item.href}
                        title={item.title}
                        target="_blank"
                    >
                        <img
                            class={`logo ${item.name}`}
                            src={item.src}
                            alt={item.alt}
                            style={item.style} 
                        />
                    </a>
                )
            )}
        </StyledDiv>
    )
}

export default EntityLogos