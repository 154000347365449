import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import useBreakpoint from "../../../hooks/useBreakpoint";

const StartNow = () => {

    return (
        <section 
            style={{backgroundColor: '#F7F5F5'}}
        >
            <Container className="py-20">
                <Row className="justify-content-center">
                    <Col>
                        <h1 className="text-center mb-9">Start now and broaden your horizon</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Link
                            to="/search"
                            className="btn btn-primary mr-8 my-4 text-uppercase w-100 w-sm-auto"
                            size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                        >
                            View vacancies
                        </Link>
                        <Link
                            to="/mentorship"
                            className="btn btn-secondary text-uppercase w-100 w-sm-auto my-4"
                            size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                        >
                            Explore mentorship
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default StartNow