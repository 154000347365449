import React from "react";
import { graphql } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import SEO from "../components/seo";
import { Helmet } from "react-helmet";
import Hero from "../components/Sections/homepage/Hero";
import Services from "../components/Sections/homepage/Services";
import Content1 from "../components/Sections/homepage/Content1";
import Slider from "../components/Sections/homepage/Slider";
import StartNow from "../components/Sections/homepage/StartNow";

const Content = styled.div`
    $ a:hover {
        color: silver;
    }
`

const Home = ({data}) => {
    
    const headerConfig = {
        render: true
    };

    const {content} = data.allSharePointPageContentList

    const heroImageData = data.desktop.childImageSharp.fluid

    return (
        <>  
            <PageWrapper headerConfig={headerConfig}>
                <SEO title="Homepage" />
                <Helmet>
                    <style>{`body { background-color: #FFECC6;`}</style>
                </Helmet>
                <Content>
                    <Hero />
                    <Services />
                    <Content1 />
                    <Slider />
                    <StartNow />
                </Content>
            </PageWrapper>
        </>
    )
}

export default Home;

export const query = graphql`
    {
        allSharePointPageContentList( filter: {data: {fields: {Title: {eq: "homepage"}}}}) 
        {
            content: nodes {
                data {
                    fields {
                        Section
                        Content
                    }
                }
            }
        }
        desktop: file(relativePath: { eq: "photo-large-lobby.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 2400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`